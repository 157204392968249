<template>
    <div class="c-product-view">
        <v-title title="Products"></v-title>

        <title-box>
            <div class="row">
                <div class="col-12">
                    <h1>Products</h1>
                </div>
            </div>
        </title-box>
        <div class="container-fluid mt-3" v-if="selectedShop.applicationId !== '0'">
            <div class="row">
                <div class="col-6">
                    <shop-agreements @changed="getProductDefinitions"></shop-agreements>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <template v-if="hasPermission('MerchantCentral/listProductDefinitionMappings')">
                        <div class="position-relative" v-if="agreement && items.length">
                            <span class="c-product-view__meta">
                                Bundled: {{isBundled ? 'YES' : 'NO'}}
                            </span>
                            <merchant-data-table class="shadow" :options="options" :items="items" ref="table"></merchant-data-table>
                        </div>
                        <p v-if="agreement && !isLoading && items.length === 0">No products found</p>
                    </template>
                    <span v-else>
                        Not allowed to see this view
                    </span>
                </div>
            </div>
        </div>
        <div class="container-fluid" v-else>
            <mercur-card class="mt-n4">
                <p>Select shop to see products</p>
            </mercur-card>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import CONFIG from '@root/config'

import ProductRow from '@/components/elements/products/Row'
import ShopAgreements from '@/components/elements/products/ShopAgreements'
import MerchantDataTable from '@/components/elements/MerchantDataTable'

export default {
    name: 'ProductsView',
    components: { ShopAgreements, MerchantDataTable },
    data () {
        return {
            items: [],
            isLoading: false,
            selectedProduct: null,
            options: {
                columns: {
                    'Products': {
                        field: 'productName',
                        cellRendererFramework: ProductRow,
                    },
                },
                pagination: false,
                animateRows: true,
                defaultColDef: {
                    menuTabs: [],
                },
            },
        }
    },
    computed: {
        ...mapState('products', ['shopAgreements', 'agreement']),
        ...mapGetters('shop', ['isBundled']),
    },
    methods: {
        getProductDefinitions () {
            if (!this.hasPermission('listProductDefinitionMappings')) {
                return
            }
            this.fetchProductDefinitions()
        },
        fetchProductDefinitions () {
            this.items = []
            if (!this.hasPermission('listProductDefinitionMappings')) {
                return
            }
            this.isLoading = true
            const url = CONFIG.API.ROUTES.PRODUCTS_MANAGEMENT.GET_PRODUCT_DEFINITIONS.replace('{agreementId}', this.agreement.agreementId)
            this.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                this.items = data
            }).finally(() => {
                this.isLoading = false
            })
        },
    },
}
</script>

<style lang="scss" scoped>
    .c-product-view {
        &__pagination-box {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .c-default-table__pagination {
                margin-bottom: 0;
            }
        }

        &__meta {
            position: absolute;
            right: 10px;
            top: 13px;
            z-index: 2;
        }

        /deep/ .ag-group-contracted, /deep/ .ag-group-expanded {
            width: 100%;
            position: absolute;
            height: 100%;
            left: 0;
        }

        /deep/ .ag-group-value {
            padding-left: 15px;
        }

    }
</style>
