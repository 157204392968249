<template>
    <div v-if="params.data" class="c-product-row">
        <div class="c-product-row__name" @click="isOpen ? close() : fetchItems()">
            {{ params.value }}
            <i class="fas" :class="isOpen ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
        </div>
        <div class="row">
            <div class="col-sm-8">
                <mercur-spinner v-if="isOpen && !items.length"></mercur-spinner>
                <div v-if="items.length && isOpen">
                    <attribute-row
                            v-for="(item, index) in items"
                            :key="index"
                            :params="item"
                            :preSelectedValues="activeItem ? activeItem.options : null"
                            @change="updateValues"
                    ></attribute-row>
                </div>
            </div>
            <div class="col-sm-4 c-product-row__labels">
                <product-label-list v-if="isOpen && items.length" :params="params" @edit="setOptions" :selectedOptions="activeItem ? activeItem.options : null"></product-label-list>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import CONFIG from '@root/config'
import AttributeRow from '@/components/elements/products/AttributeRow'
import ProductLabelList from '@/components/elements/products/LabelList'

export default Vue.extend({
    name: 'ProductRow',
    components: { ProductLabelList, AttributeRow },
    data () {
        return {
            activeItem: null,
            isOpen: false,
            items: [],
        }
    },
    computed: {
        url () {
            if (!this.params.data) {
                return null
            }
            return CONFIG.API.ROUTES.PRODUCTS_MANAGEMENT.GET_ATTRIBUTES_BY_SHOP_AND_PRODUCT
                .replace('{productHash}', this.params.data.productHash)
                .replace('{shopHash}', this.$store.state.products.agreement.shopHash)
        },
    },
    methods: {
        setOptions (item) {
            this.$set(this, 'activeItem', item)
        },
        updateValues (params) {
            if (!this.activeItem) {
                return
            }
            if (Array.isArray(this.activeItem.options)) {
                this.activeItem.options = {}
            }

            if (params.isReset) {
                this.$delete(this.activeItem.options, [params.key])
                return
            }

            if (params.value) {
                this.activeItem.options[params.key] = params.value
            }
        },
        fetchItems () {
            this.params.node.setRowHeight(300)
            this.params.api.onRowHeightChanged()
            this.isOpen = true
            this.post(this.url, {}).then(({ data }) => {
                this.items = data
                let height = this.items.length * 46 + 55 // height for number of attributes
                this.items.forEach((item) => {
                    height += Math.ceil(item.attributeOptions.length / 4) * 55
                })

                if (height < 300) {
                    height = 300
                }

                this.params.node.setRowHeight(height)
                this.params.api.onRowHeightChanged()
            })
        },
        close () {
            this.isOpen = false
            this.params.node.setRowHeight(46)
            this.params.api.onRowHeightChanged()
            this.items = []
        },
    },
})
</script>

<style lang="scss" scoped>
    .c-product-row {
        cursor: pointer;
        overflow: hidden;
        align-self: flex-start;
        flex-grow: 1;

        &__labels {
            border-left: 1px solid #e2e2e2;
        }
    }
</style>
