<template>
    <div class="c-label-list">
        <div class="row c-label-list__header">
            <div class="col d-flex">
                <h2 class="font-weight-normal mr-2">List of product labels</h2>
                <add-label message="Product definition label was added" :action="action" @success="appendLabel"></add-label>
            </div>
        </div>
        <div class="c-label-list__scroller"  :style="{ height: params.node.rowHeight - 115 + 'px' }">
            <mercur-spinner v-if="isFetchingResources"></mercur-spinner>
            <label-listing v-if="items.length" @edit="editProductLabel" @update="updateLabel" @delete="deleteLabel" ref="listing" :items="sortedLabels"></label-listing>
        </div>
    </div>
</template>

<script>
import ResourcesView from '@/views/ResourcesView'
import CONFIG from '@root/config'
import { mapState } from 'vuex'
import AddLabel from '@/components/elements/products/AddLabel'
import LabelListing from '@/components/elements/products/LabelListing'

export default {
    name: 'ProductLabelList',
    mixins: [ ResourcesView ],
    components: { AddLabel, LabelListing },
    props: {
        params: {},
        selectedOptions: {
            default: function () {
                return {}
            },
        },
    },
    computed: {
        ...mapState('products', ['agreement']),
        action () {
            return CONFIG.API.ROUTES.PRODUCTS_MANAGEMENT.SET_PRODUCT_MAPPING
                .replace('{productHash}', this.params.data.productHash)
                .replace('{agreementId}', this.agreement.agreementId)
                .replace('{shopHash}', this.agreement.shopHash)
        },
        sortedLabels () {
            if (!this.items) {
                return
            }
            const itemsCopy = JSON.parse(JSON.stringify(this.items))

            return itemsCopy.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
        },
    },
    methods: {
        appendLabel (data) {
            this.items.push(data)
        },

        updateLabel (label) {
            for (let item in this.selectedOptions) {
                if (!this.selectedOptions[item] || (this.selectedOptions[item] && !this.selectedOptions[item].length)) {
                    delete this.selectedOptions[item]
                } else if (!Array.isArray(this.selectedOptions[item])) {
                    this.selectedOptions[item] = [this.selectedOptions[item]]
                }
            }

            const payload = {
                options: this.selectedOptions,
                label: label.label,
            }

            this.post(this.action, payload, 'Product definition label was updated').then(({ data }) => {
                const index = this.items.findIndex(item => item.label === data.label)
                this.$set(this.items, [index], data)
                this.$emit('edit', null)
                this.$refs.listing.activeLabel = null
                this.$refs.listing.isAction = false
            })
        },

        deleteLabel (label) {
            const url = CONFIG.API.ROUTES.PRODUCTS_MANAGEMENT.REMOVE_PRODUCT_MAPPING
                .replace('{productHash}', this.params.data.productHash)
                .replace('{agreementId}', this.agreement.agreementId)

            const payload = {
                label: label.label,
            }

            this.post(url, payload, 'Product definition label was deleted').then(({ data }) => {
                const index = this.items.findIndex(item => item.label === data.label)
                this.$delete(this.items, [index])
                this.$emit('edit', null)
                this.$refs.listing.activeLabel = null
                this.$refs.listing.isAction = false
            })
        },

        editProductLabel (item) {
            this.$emit('edit', JSON.parse(JSON.stringify(item)))
        },
    },
    created () {
        this.url = CONFIG.API.ROUTES.PRODUCTS_MANAGEMENT.LIST_PRODUCT_DEFINITION_MAPPINGS
            .replace('{productHash}', this.params.data.productHash)
            .replace('{shopHash}', this.agreement.shopHash)
    },
}
</script>

<style lang="scss" scoped>
    .c-label-list {
        padding: 40px 0 0;
        position: relative;

        &__header {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding: 0 15px;
            z-index: 4;
        }

        &__scroller {
            overflow-y: scroll;
            padding: 0 15px;
        }

        h2 {
            margin: 0;
            color: #333333;
        }

    }
</style>
