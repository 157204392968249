<template>
    <div v-if="params" class="c-attribute-row">
        <div class="row">
            <div class="col-12">
                <div v-if="isBundled">
                    <input :id="`all-${params.attributeName}`" @change="selectAll" v-model="isAllSelected" class="c-attribute-row__attribute" type="checkbox">
                    <label :for="`all-${params.attributeName}`"><strong>{{ params.attributeName | underlineToSpace }}</strong></label>
                </div>
                <template v-else>
                    <h3>{{ params.attributeName | underlineToSpace }} <button class="btn btn-icon" @click.prevent="resetField"><i class="fas fa-trash"></i></button></h3>
                    <p class="c-attribute-row__error" v-if="hasMultipleOptions">Data came with multiple options selected, (selected values: <b>{{ wrongData }}</b>) please select only one and save</p>
                </template>
            </div>
        </div>
        <div class="row">
            <div class="col-3" v-for="(option, index) in params.attributeOptions" :key="index">
                <div v-if="isBundled">
                    <input :id="`all-${option}`" @change="selectAll" v-model="selectedValue" :value="option" class="c-attribute-row__attribute" type="checkbox">
                    <label :for="`all-${option}`">{{ option | underlineToSpace }}</label>
                </div>
                <div v-else>
                    <input type="radio" :id="`radio-${option}`" v-model="selectedValue" :value="option">
                    <label for="`radio-${option}`">{{ option | underlineToSpace }}</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'AttributeRow',
    props: {
        params: null,
        preSelectedValues: {
            default: null,
        },
    },
    data () {
        return {
            selectedValue: null,
            isAllSelected: false,
            wrongData: null,
        }
    },
    watch: {
        preSelectedValues () {
            this.wrongData = null
            if (!this.preSelectedValues) {
                this.selectedValue = this.isBundled ? [] : null
                this.isAllSelected = false
                return
            }

            let attr = this.preSelectedValues[this.params.attributeName]
            if (!attr) {
                this.resetField()
                this.selectedValue = this.isBundled ? [] : null
                return
            }

            if (this.isBundled) {
                this.selectedValue = []
                if (attr) {
                    if (typeof this.preSelectedValues[this.params.attributeName] === 'string') {
                        attr = [attr]
                    }

                    this.selectedValue = attr
                }

                return
            }

            if (attr && !Array.isArray(attr)) {
                this.selectedValue = attr
            } else if (attr && Array.isArray(attr)) {
                this.wrongData = JSON.parse(JSON.stringify(attr))

                if (this.wrongData.length > 1) {
                    this.selectedValue = null
                    return
                }

                this.selectedValue = attr[0]
            }
        },
        selectedValue () {
            if (this.isBundled && this.params.attributeOptions && this.selectedValue.length === this.params.attributeOptions.length) {
                this.isAllSelected = true
            } else {
                this.isAllSelected = false
            }

            this.$emit('change', { 'key': this.params.attributeName, 'value': this.selectedValue })
        },
    },
    computed: {
        ...mapGetters('shop', ['isBundled']),
        hasMultipleOptions () {
            if (!this.wrongData || !Array.isArray(this.wrongData)) {
                return false
            }

            return this.wrongData.length > 1
        },
    },
    methods: {
        resetField () {
            this.$set(this, 'selectedValue', null)
            this.$emit('change', { 'key': this.params.attributeName, isReset: true })
        },
        selectAll () {
            if (this.isAllSelected === true) {
                this.selectedValue = this.params.attributeOptions.map(e => e)
            } else {
                this.selectedValue = []
            }
        },

    },
}
</script>

<style lang="scss" scoped>
    .c-attribute-row  {
        padding: 0;
        border-bottom: 1px solid #e2e2e2;

        h3 {
            font-weight: bold;
            margin: 0;

            button {
                float: right;
                margin-top: 6px;
                margin-right: 10px;
            }
        }

        &__error {
            font-size: 12px;
            color: #ff1744;
            margin: -15px 0 0;
            line-height: 1rem;
            position: absolute;
            white-space: normal;
        }

        &__attribute {
            text-transform: uppercase;
            font-weight: bold;
        }
    }
</style>
